<template>
  <div class="pages">
    <div class="task_box">
      <div class="dialog">
        <div class="zuBg">
          <div class="btnBg">
            <div class="title">创建账户</div>
            <div class="inputList">
              <div class="icon">
                <img src="../../assets/images/dialoglogo1.png" alt="" />
              </div>

              <div class="iptTitle">账号</div>
              <div class="inp">
                <input
                  v-model="username"
                  type="text"
                  placeholder="请输入账号"
                />
              </div>
            </div>
            <div class="inputList">
              <div class="icon">
                <img src="../../assets/images/dialoglogo2.png" alt="" />
              </div>
              <div class="iptTitle">密码</div>
              <div class="inp">
                <input
                  v-model="password"
                  type="text"
                  placeholder="请输入密码"
                />
              </div>
            </div>
            <div class="inputList">
              <div class="icon">
                <img src="../../assets/images/dialoglogo3.png" alt="" />
              </div>

              <div class="iptTitle">角色</div>
              <el-select
                v-model="role"
                placeholder="请选择角色"
                @change="changeRole"
              >
                <el-option
                  v-for="item in roleList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </div>
            <div class="inputList">
              <div class="icon">
                <img src="../../assets/images/dialoglogo4.png" alt="" />
              </div>
              <div class="iptTitle">所属组织</div>
              <el-cascader
                v-model="website_id"
                :options="siteList"
                :show-all-levels="false"
                placeholder="请选择所属组织"
                :props="customProps"
                @change="handleCheckChange"
              />
              <!-- <el-cascader :props="customProps" ref="tree" :options="siteList" v-model="website_id"
            @change="handleCheckChange" placeholder="请选择所属项目" :show-all-levels="false"></el-cascader> -->
            </div>
            <div class="tijiao" @click="submit">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      siteList: [],
      customProps: {
        children: 'children',
        value: 'id',
        label: 'title',
      },
      website_id: '',
      organize_id: '',
      username: '',
      password: '',
      role: '',
      group_id: '', // 角色id
      roleList: [],
    }
  },
  mounted() {
    // 获取表格列表数据
    this.getTypeList()
    this.getzuzhiList()
  },
  methods: {
    async submit() {
      const res = await this.$http.post('user/register', {
        username: this.username,
        password: this.password,
        group_id: this.group_id,
        website_id: this.website_id,
      })
      if (res.code !== 1) return this.$message.error(res.msg)

      this.$message.success(res.msg)
      this.username = ''
      this.password = ''
      this.group_id = ''
      this.role = ''
      this.website_id = ''
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 角色获取
    async getTypeList() {
      // const res = await this.$http.post('group/menu')
      const res = await this.$http.post('group')
      this.roleList = res.data.list
      // const type = this.roleList.map(option=>{
      //   return{
      //     value: option.id,
      //     label: option.title
      //   }
      // })
      // this.roleList = type
    },
    changeRole(e) {
      console.log(e, 'ee')
      this.group_id = e
    },
    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post('Organize')
      this.siteList = res.data
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate)
      this.website_id = data[data.length - 1]

      // let lastValue;
      // data.forEach(subArr => {
      //     if (Array.isArray(subArr)) {
      //         subArr.forEach((value) => {
      //             lastValue = value;
      //         });
      //     } else {
      //         lastValue = subArr;
      //     }
      // });
      // console.log(lastValue,'lastValue');
      // this.organize_id = lastValue
      //  let res = this.$refs.tree.getCheckedNodes()
      //   let arr = []
      //   res.forEach((item) => {
      //     arr.push(item.data.id)
      //   })
      //   this.organize_id = arr.join(',');
    },
  },
}
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;

  display: flex;
  justify-content: center;
}
.task_box {
  width: calc(100% - 220px);
}
.dialog {
  // position: relative;
  .zuBg {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    // height: 643px;
  }
  .btnBg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    width: 100%;
    height: 600px;
    background: url('../../assets/images/editDialog.png') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .btnBg .title {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #4486ff;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .inputList {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    border: 2px solid #4486ff;
    border-radius: 4px;
    margin-top: 13px;
    width: 610px;
    height: 50px;
    margin-bottom: 30px;
    .icon {
      width: 26px;
      height: 24px;
      img {
        width: 26px;
        height: 24px;
      }
    }
    padding: 0 40px;
    box-sizing: border-box;
  }
  .iptTitle {
    width: 100px;
    margin-left: 16px;
    display: flex;
    justify-content: flex-start;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 30px;
  }
  input {
    height: 52px;
    font-size: 20px;
    outline: none;
    border: none;
  }
  .inp input {
    background: rgba(0, 0, 0, 0);
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cccccc;
  }
  .inputList .el-select > .el-input {
    border: none !important;
    height: 56px;
    background: rgba(0, 0, 0, 0);
  }
  .el-select {
    width: 300px !important;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .el-cascader {
    cursor: pointer;
    width: 300px;
  }
  ::v-deep .el-input__inner {
    margin-top: 2px;
    font-size: 20px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #fff !important;
    height: 52px;
    border: none;
    width: 420px !important;
    padding-left: 0;
    background: rgba(0, 0, 0, 0) !important;
    border: none !important;
  }
  .inputList .el-select .el-input .el-select__caret {
    font-size: 26px !important;
  }
  .inputList .el-input__icon {
    line-height: 51px !important;
  }
  .tijiao {
    width: 160px;
    height: 50px;
    background: #4486ff;
    border-radius: 4px;
    font-size: 29px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #ffffff;
    text-align: center;
    line-height: 50px;
    margin-top: 29px;
  }
  .close {
    position: absolute;
    top: 183px;
    right: 455px;
    width: 36px;
    height: 36px;
    z-index: 1000000;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
